import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth';
import academico from '@/modules/academico';
import banner from '@/modules/banner';
import sedes from '@/modules/sedes';
import tipoDocumento from '@/modules/tipoDocumento';
import estadoCivil from '@/modules/estadoCivil';
import regimenSalud from '@/modules/regimenSalud';
import nivelFormacion from '@/modules/nivelFormacion';
import ocupacion from '@/modules/ocupacion';
import grupoEtnico from '@/modules/grupoEtnico';
import student from '@/modules/student';
import discapacidad from '@/modules/discapacidad';
import ciudad from '@/modules/ciudad';
import tutoria from '@/modules/tutoria';

import router from './router'
import {version} from '../package.json';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    processing: false,
    warning: null,
    error: null,
    packageVersion : version,
    show_notification: true,
    isDebug: false,
    baseUrl: process.env.BASE_URL,
    nodeEnv: process.env.NODE_ENV,
    appInstance: process.env.VUE_APP_INSTANCE
  },
  getters:{

  },
  mutations: {
    startProcessing (state) {
      state.processing = true;
    },
    setDebug (state, value) {
      state.isDebug = value;
    },
    stopProcessing (state) {
      state.processing = false;
    },
    setError (state, responseApi) {
      if(responseApi.data){
        let data = responseApi.data
        let data_error = data && data.error && data.error.message ? data.error : data
        if(data_error){
          if(data_error.message){
            if(data_error.name && data_error.name=='InvalidJwtToken'){
              state.error = 'Verificación de identidad. Por favor cierre la aplicación e intente nuevamente.';
              this.dispatch('auth/logout')
              router.push('/')
            }else{
              state.error = data_error.message;  
            }
          }else{
            if (data_error.error) {
              state.error = data_error.error;
            } else {
              state.error = data_error;
            }
          }  
        }else{
          if (data.error) {
            state.error = data.error;
          } else {
            state.error = data;
          }
        }
      }else{
        if(responseApi.ok==false){
          state.error = 'Verifique su conexión a internet.';
        } else {
          state.error = responseApi;
        }
        console.log(responseApi)
      }
      
      setTimeout(() => {
        state.error = null
      },3000)

    },
    setWarning (state, value) {
      state.warning = value
      return new Promise((resolve) => {
        setTimeout(function () {
          state.warning = null
          resolve()
        }, 3000)
      })
    },
    setShowNotification:  (state, value) => {
      state.show_notification = value
    }
  },
  actions: {},
  modules: {
    auth,
    academico,
    banner,
    sedes,
    tipoDocumento,
    estadoCivil,
    regimenSalud,
    nivelFormacion,
    ocupacion,
    grupoEtnico,
    discapacidad,
    student,
    ciudad,
    tutoria
  }
})


export default store;

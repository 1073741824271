import Vue from 'vue';

const state = {
  me: null,
  programaciones:null,
  variables:null,
};

const actions = {
    
  informacion: ({commit}, userInput) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.get('informacion')
        .then(response => {
            commit('setData',response.data);
            resolve(response.data);
        })
        .catch(error => {
          commit('setError', error, { root: true });
          reject(error);
        })
        .finally(() => {
          commit('stopProcessing', null, { root: true });
        })
    })
  },
  
  verificarVariables:({commit,dispatch}) => {
    //commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('verificar-variables').then(
        response =>{
          commit('setVariables',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      })
    })  
  },
  fetchProgramaciones: ({commit}, userInput) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.get('programaciones')
        .then(response => {
            commit('setProgramaciones',response.data);
            resolve(response.data);
        })
        .catch(error => {
          commit('setError', error, { root: true });
          reject(error);
        })
        .finally(() => {
          commit('stopProcessing', null, { root: true });
        })
    })
  }
  
};

const getters = {
  getData: (state) => () =>{
    return state.me
  },
};

const mutations = {
  setData: (state, me) => {
    state.me = me;
  },
  setProgramaciones: (state, datos) => {
    state.programaciones = datos;
  },
  restart: (state) => {
    state.me = null
  },
  setVariables: (state, variables) => {
    state.variables = variables;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

import Vue from 'vue';

const state = {
  user: null,
  logged: !!window.localStorage.getItem('_token'),
  mensajes: [],
};

const actions = {
  login: ({commit}, userInput) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('login', {user: userInput})
        .then(user => {
            window.localStorage.setItem('_token', user.body.token);
            let now = new Date()
            let timestamp = now.getTime();
            var object = {start: timestamp, keep: userInput.mantener_sesion, end: null};
            if (userInput.mantener_sesion) {
              now.setDate(now.getDate() + 15);
            } else {
              now.setMinutes(now.getMinutes() + 10);
            }
            object.end = now.getTime();
            window.localStorage.setItem('_token_timestamp', JSON.stringify(object));
            commit('setUser');
            resolve(user);
        })
        .catch(error => {
          commit('setError', error, { root: true });
          reject(error);
        })
        .finally(() => {
          commit('stopProcessing', null, { root: true });
        })
    })
  },
  profile: ({commit}, userInput) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('profile', {user: userInput})
        .then(user => {
            window.localStorage.setItem('_token', user.body.token);
            commit('setUser');
            resolve(user);
        })
        .catch(error => {
          commit('setError', error, { root: true });
          reject(error);
        })
        .finally(() => {
          commit('stopProcessing', null, { root: true });
        })
    })
  },

  verificarMensajes:({commit,dispatch}) => {
    //commit('startProcessing', null, { root: true });
    
      Vue.http.post('verificar_mensajes').then(
        response =>{
          // mantener_sesion
          // let object = JSON.parse(window.localStorage.getItem('_token_timestamp'));
          // let cerrar_sesion = true;
          // if (object) {
          //   let logoutEnd = object.end,
          //   now = new Date().getTime();
          //   if (logoutEnd > now) {
          //     cerrar_sesion = false;
          //   }
          // }
          // if (cerrar_sesion) {
          //   commit("setError", 'Verificación de identidad. Por favor cierre la aplicación e intente nuevamente.', { root:true })
          //   dispatch('logout')
          // }
          
          commit('setMensajes',response.data);
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
    
      }).finally(()=>{
        //commit('stopProcessing', null, { root: true });
      })
  },

  logout: ({commit}) => {
    window.localStorage.removeItem('_token');
    window.localStorage.removeItem('_token_timestamp');
    commit('setUser');
    commit('setMensajes',[]);
  }

  // banners publicitario


};

const getters = {
  
};

const mutations = {
  //establecemos el user a través del token jwt
  setUser: (state) => {
    if(window.localStorage.getItem('_token')) {
      const token = window.localStorage.getItem('_token');
      const jwtDecode = require('jwt-decode');
      state.user = jwtDecode(token);
      state.logged = true;
    } else {
      state.logged = false;
      state.user = null;

    }
  },
  //establecemos el estado del usuario
  setLogged: (state, logged) => {
    state.logged = logged;
  },

  setMensajes: (state, mensajes) => {
    state.mensajes = mensajes;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

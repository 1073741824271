  <template>
  <div class="container">

    <div class="agendamiento_fila row" v-if="tutoria">
        <div class="col-md">
          <div>{{textoTipoClase}}</div>
          <div v-if="textoTema" :class="{'cancelado':tutoria.estado=='cancelado'}">
            <b-icon icon="exclamation-circle-fill" variant="info" @click="mostrarNotificacionTema(tutoria.tema)" v-if="tutoria.tema && tutoria.tema.notificacion_mostrar && tutoria.tema.notificacion_texto"></b-icon>
            {{textoTema}}
            
          </div>
          <div class="strong">{{textoFechaClase}}</div>
          
        </div>
        <div class="col-md">
          <div>Estado: <strong>{{textoEstado}}</strong><span v-if="fechaCancelado"> {{fechaCancelado}}</span>
          </div>
          
          <div v-if="textoSede">
            Sede: <strong>{{textoSede}}</strong>
          </div>
          <div v-if="textoSalon">Salón: 
            <strong v-if="salonUrl && mostrarSalon && mostrarSalonFinal"><a  @click="procesarAceptar()" :href="salonUrl" target="_blank">{{textoSalon}}</a></strong>
            <strong v-else>{{textoSalon}}</strong>
          </div>
          
          <div v-if="tutoria.estudiante && tutoria.estudiante.progresos">
            <div v-for="ag in tutoria.estudiante.progresos" v-if="ag.tema_id == tutoria.tema_id">Docente: <strong>{{docent(ag.docente)}}</strong>
            </div>
          </div>
          
          <div v-if="textoPoliticaCancelacion">Política de cancelación: <strong>{{textoPoliticaCancelacion}}</strong>
          </div>

          <div v-if="tutoria.conformidad_fecha">Aceptado: <strong>{{textoConformidadFecha}}</strong></div>
        </div>
        
        <div class="col-md">
          <button v-if="puedeCancelar" @click="cancelando=true" class="btn btn-danger btn-sm" >Quiero cancelar</button>
          <button v-if="[1,0].includes(tutoria.encuestar) && tutoria.estado == 'asistido' && tutoria.showEncuesta" class="btn btn-info btn-sm" @click="mostrarEncuesta">
            <b-icon  icon="chat-left-dots-fill"></b-icon>
          </button>

          <b-modal  hide-header-close  v-model="cancelando" id="modalcancelarseguro" hide-footer :title="textoConfirmarCancelar">
            <b-btn variant="success" @click="procesarCancelar">Si</b-btn>
            <b-btn variant="danger" @click="cancelando=false">No</b-btn>
          </b-modal>

        </div>

    </div>
    
    <b-modal  no-close-on-backdrop hide-header-close ok-only ok-title="Omitir"
      v-model="mostrandoNotificacionTema"   id="modalNotificacionTema" :title="tituloNotificacionTema">
      <p>
        {{textoNotificacionTema}}
      </p>
      <br>
      <p class="text-center">
        <b-link v-if="enlaceNotificacionTema" :href="enlaceNotificacionTema" target="_blank">Más información</b-link>
      </p>
    </b-modal>

    <div v-if="mensajeTransition">
      <BlockUI :message="mensajeTransition"></BlockUI>
    </div>

    <Encuesta v-if="encuestaMostrar" :agendamiento="tutoria" @cerrar="cerrarEncuesta" :mostrar="encuestaMostrar"/>
    
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Encuesta from '@/components/Encuesta';

  export default {
    name: 'ProgramacionesTutoria',
    components: {
      Encuesta
    },
    props:{
      tutoria: Object,
      estudiante: Object
    },
    data () {
      return {
        tema_id_local : null,
        texto_cambio_tipo : null,
        cambiando_tema : false,
        texto_cambio_tipo: null,
        cancelando: false,
        tituloNotificacionTema:null,
        mostrandoNotificacionTema : false,
        textoNotificacionTema:null,
        enlaceNotificacionTema:'#',
        encuestaMostrar: false,
        mensajeTransition:null
      }
    },
    
    mounted () {

    },
    methods:{
      ...mapActions({
        fetchClases: 'tutoria/fetchTutorias',
        cancelar: 'tutoria/cancelar',
        aceptar: 'tutoria/aceptar'
      }),
      ...mapMutations({

      }),
      recargar(){
        this.fetchClases().then(()=>{})
      },
      
      procesarCancelar(){
        this.cancelar(this.tutoria.id).then(()=>{
        }).finally(()=>{
          this.cancelando=false
        })
      },
      procesarAceptar(){
        this.aceptar(this.tutoria.id).then(()=>{
        }).catch((error) => {
          console.error(error);
        })
      },
      docent(docente){
        if(docente){
          docente = JSON.parse(docente)
          return docente.nombre
        }
        else{return ""}
      },
      mostrarNotificacionTema(tema) {
        if (tema && tema.notificacion_mostrar && tema.notificacion_texto) {
          this.mostrandoNotificacionTema = true;
          this.textoNotificacionTema = tema.notificacion_texto
          this.enlaceNotificacionTema = tema.notificacion_enlace;
          this.tituloNotificacionTema = tema.nombre;
        }
      },
      mostrarEncuesta() {
        this.encuestaMostrar = true;
      },
      cerrarEncuesta() {
        this.tutoria.encuestar = null;
        this.encuestaMostrar = false;
      }
    },
    computed: {
      ...mapState({
        cursos: state => state.clases.cursos, 
        sedeActual: state => state.academico.sedeActual,
      }),
      ...mapGetters({
        getCurso: 'cursos/getCursoDetalle', 
      }),
      
      puedeCancelar(){
        if(this.tutoria){
          if( this.tutoria.estado=='solicitado' || this.tutoria.estado=='asignado'){
            let ahora = new Date()
            if(this.tutoria.politica_cancel_tiempo){
              let deadline = new Date(this.tutoria.politica_cancel_tiempo)
              if(ahora>deadline){
                return false
              }
              return true
            }else{
              let fecha_clase = new Date(this.tutoria.clase.fecha_bloque)
              if(ahora>fecha_clase){
                return false
              }
              return true
            }
          }
        }
        return false
      },
      salonUrl() {
        if (this.tutoria.clase.salon && this.tutoria.clase.salon.url) {
          return this.tutoria.clase.salon && this.tutoria.clase.salon.url;
        }
        return null;
      },
      mostrarSalon() {
        if(this.tutoria){
          if(this.tutoria.clase && this.tutoria.clase.salon){
            let ahora = new Date()
            let ahoraTimestamp = ahora.getTime();
            let limite = new Date(ahoraTimestamp+300000)

            let fecha_clase = new Date(this.tutoria.clase.fecha_bloque)

            if(limite>fecha_clase){
              return true;
            }
          }
        }
        return false;
      },
      mostrarSalonFinal() {
        if(this.tutoria){
          if(this.tutoria.clase && this.tutoria.clase.salon){
            let ahora = new Date()
            let fecha_clase = new Date(this.tutoria.clase.fecha_bloque)
            let fecha_clase_final = new Date(fecha_clase.getTime()+(300000*3))
            if(ahora<fecha_clase_final){
              return true;
            }
          }
        }
        return false;
      },
      textoSalon(){
        if(this.tutoria){
          if(this.tutoria.estado == 'cancelado'){
            return ''
          }
          if (this.mostrarSalon) {
            if(this.tutoria.clase && this.tutoria.clase.salon){
              if(this.tutoria.clase && this.tutoria.clase.sede && this.tutoria.clase.sede.es_virtual && this.tutoria.clase.sede.es_virtual=='1'){
                if(this.tutoria.clase.salon && this.tutoria.clase.salon.url){
                  return this.tutoria.clase.salon.nombre;//`<a @click="procesarAceptar()" href="${this.tutoria.clase.salon.url}" target="_blank">${this.tutoria.clase.salon.nombre}</a>`
                }else{
                  return "Desconocido"
                }
                
              }else{
                let salon = this.tutoria.clase.salon
                let texto = salon.nombre
                return texto  
              }
            }
          }
        }
        return 'Puede verlo 5 minutos antes de la clase. Recuerde hacer click en Actualizar'
      },
      textoSede(){
        if(this.tutoria && this.tutoria.sede && this.tutoria.sede.nombre){
          return   this.tutoria.sede.nombre
        }
        return ''
      },
      textoTipoClase(){
        if(this.tutoria ){
          let texto 
          if(this.tutoria.tipo_solicitud == 'normal'){
            texto = 'Clase'
          }else if(this.tutoria.tipo_solicitud == 'tutoria'){
            texto = 'Tutoría'
          }else if(this.tutoria.tipo_solicitud == 'refuerzo'){
            texto = 'Refuerzo'
          }else if(this.tutoria.tipo_solicitud == 'asistente'){
            if(this.tutoria.tipo_clase == 'lab'){
              texto = 'Laboratorio'
            }else{
              texto = this.tutoria.tipo_clase  
            }
          }else{
            texto = this.tutoria.tipo_clase
          }
          return texto
        }
        return ''
      },
      textoTema(){
        if(this.tutoria && this.tutoria.tema){
          let texto = this.tutoria.tema.nombre
          return texto
        }
        return null
      },
      observaciones(){
        if(this.tutoria && this.tutoria.observaciones){
          return  JSON.parse(this.tutoria.observaciones)
        }
        return null
      },
      textoFechaClase(){
        if(this.tutoria && this.tutoria.clase){
          //let texto = this.tutoria.fecha+', '+this.tutoria.hora
          let fechaBloque = new Date(this.tutoria.clase.fecha_bloque)
          let texto  = fechaBloque.toLocaleString('co-ES')  
          return texto
        }
        return ''
      },
      textoAsistenciaFecha(){
        if(this.tutoria && this.tutoria.asistencia_fecha){
          let asistencia_fecha = new Date(this.tutoria.asistencia_fecha).toLocaleString('co-ES')
          return asistencia_fecha
        }
        return null
      },
      observacionCancelado(){
        if(this.tutoria){
          if(this.tutoria.estado=='cancelado'){
            if(this.tutoria.observaciones){
              let observaciones = JSON.parse(this.tutoria.observaciones)
              let ultimaObservacion = observaciones.pop()
              if(ultimaObservacion && ultimaObservacion.texto){
                if(ultimaObservacion.texto.indexOf('Cancelado')>=0){
                  return ultimaObservacion
                }
              }
              
            }
          }
        }
        return null
      },
      fechaCancelado(){
        let observacionCancelado = this.observacionCancelado
        if(observacionCancelado && observacionCancelado.fecha){
          let fechaCancelado = new Date(observacionCancelado.fecha)
          if (fechaCancelado.toString() === 'Invalid Date') return observacionCancelado.fecha;
          return fechaCancelado.toLocaleString('co-ES')  
        }
        return null
      },
      textoEstado(){
        if(this.tutoria){
          if(this.tutoria.estado=='solicitado'){
            return 'Confirmado'
          }else if(this.tutoria.estado=='asignado'){
            return 'Confirmado'
          }else if(this.tutoria.estado=='notificado'){
            return 'Notificado'
          }else if(this.tutoria.estado=='cancelado'){
            let canceladoTexto = 'Cancelado'
            let observacionCancelado = this.observacionCancelado
            if(observacionCancelado && observacionCancelado.texto){
              canceladoTexto = observacionCancelado.texto
            }
            return canceladoTexto
          }else if(this.tutoria.estado=='asistido'){
            return 'Confirmado'
          }else if(this.tutoria.estado=='ausencia'){
            return 'Ausente'
          }else if(this.tutoria.estado=='asistente'){
            return 'Asistente'
          }else if(this.tutoria.estado=='aceptado'){
            return 'Aceptado'
          }else{
            return this.tutoria.estado
          }  
        }
        return ''
      },
      textoConformidadFecha(){
        if(this.tutoria && this.tutoria.conformidad_fecha){
          let conformidad_fecha = new Date(this.tutoria.conformidad_fecha).toLocaleString('co-ES')
          return conformidad_fecha
        }
        return 'Pendiente'
      },
      textoConfirmarCancelar(){
        let texto =  "¡RECUERDA! solo podrás CANCELAR esta tutoria con 8 HORAS de anticipación. Esto con el fin de que otros compañeros tengan el cupo abierto y puedan programar sus tutorias. No dejes de Avanzar."
        return texto + ' ¿Estás seguro que deseas cancelar tu tutoria de '+this.textoFechaClase+'?'
      },
      
      textoPoliticaCancelacion(){
        if(this.tutoria.estado!='cancelado'){
          return this.tutoria.politica_cancel_mensaje ? this.tutoria.politica_cancel_mensaje : false;  
        }
        return false
      }
    }
  }
</script>

<style scope>
.agendamiento_fila {
    border-top: 1px solid #bbb;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 0 !important;
}
.agendamiento_fila  .strong{
  font-weight: bolder;
}
.agendamiento_fila  span{
  font-size: 8px;
  line-height: 8px;
  color:#999;
}
.agendamiento_fila  p{
      margin-bottom: 5px !important;
}
.agendamiento_fila  button{
      margin: 5px !important;
}
.cambiar-tema{
  padding: 10px;
    border: 1px solid grey;
}
.cancelado{
  text-decoration: line-through;
}
</style>